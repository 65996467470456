import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { VIDEO_CAROUSEL_DIFFICULTY } from "domains/home/constants";
import { VideoCarouselDifficulty } from "domains/home/interfaces";
import Button, { ButtonProps } from "domains/ui/components/Button";
import { CarouselNavButton } from "domains/ui/components/Carousel";
import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";
import _, { debounce } from "lodash";

import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";

type VideoCarouselItem = Omit<
  BoxProps & ButtonProps,
  "id" | "title" | "duration" | "difficulty"
> & {
  id: string;
  title: React.ReactNode;
  difficulty: VideoCarouselDifficulty;
  duration: number;
  coverUrl?: string;
};

export interface VideoCarouselProps {
  items: VideoCarouselItem[];
  skeleton?: boolean;
}

export default function VideoCarousel({
  items,
  skeleton: isSkeleton,
}: VideoCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    skipSnaps: true,
  });
  const [isPrevScrollable, setIsPrevScrollable] = useState<boolean>(false);
  const [isNextScrollable, setIsNextScrollable] = useState<boolean>(false);

  const scrollNext = () => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();
    const slidesInViewCount = emblaApi.slidesInView().length - 1 || 1;
    const nextIndex = currentIndex + Math.round(slidesInViewCount * 0.8);
    emblaApi.scrollTo(nextIndex);
  };
  const scrollPrev = () => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();
    const slidesInViewCount = emblaApi.slidesInView().length - 1 || 1;
    const nextIndex = currentIndex - Math.round(slidesInViewCount * 0.8);
    emblaApi.scrollTo(nextIndex);
  };

  const updateScrollable = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setIsPrevScrollable(emblaApi.canScrollPrev());
      setIsNextScrollable(emblaApi.canScrollNext());
    },
    [setIsPrevScrollable, setIsNextScrollable]
  );

  const updateScrollableWithDebounce = useMemo(
    () => debounce(updateScrollable, 500),
    [updateScrollable]
  );

  useEffect(() => {
    if (!emblaApi) return;
    updateScrollable(emblaApi);
    emblaApi.on("select", updateScrollable);
    emblaApi.on("resize", updateScrollableWithDebounce);
    return () => {
      if (!emblaApi) return;
      emblaApi.off("select", updateScrollable);
      emblaApi.off("resize", updateScrollableWithDebounce);
    };
  }, [emblaApi, updateScrollable, updateScrollableWithDebounce]);

  return (
    <Box
      ref={!isSkeleton ? emblaRef : undefined}
      pos="relative"
      overflow="hidden"
      mx={-9}
      px={9}
    >
      <Flex
        sx={{
          backfaceVisibility: "hidden",
          touchAction: "pan-y",
        }}
        align="stretch"
        ml={-5}
      >
        {isSkeleton
          ? _.range(6).map((index) => (
              <VideoCarouselItem key={`skeleton-${index}`}>
                <VStack align="stretch" w="100%" h="100%" spacing={3}>
                  <Box pos="relative" w="100%" pt="60%">
                    <Skeleton
                      pos="absolute"
                      top={0}
                      left={0}
                      w="100%"
                      h="100%"
                      borderRadius="lg"
                    />
                  </Box>

                  <VStack align="stretch" flex={1} spacing={2}>
                    <SkeletonText w="30%" noOfLines={1} skeletonHeight={3} />
                    <SkeletonText w="70%" noOfLines={1} skeletonHeight={4} />
                  </VStack>
                </VStack>
              </VideoCarouselItem>
            ))
          : items.map(
              (
                { id, title, difficulty, duration, coverUrl, ...restProps },
                index
              ) => (
                <VideoCarouselItem
                  key={`item-${id}-${index}`}
                  as={Button}
                  w="100%"
                  _hover={{ opacity: 0.8, bgColor: "transparent" }}
                  _active={{ opacity: 0.9, bgColor: "transparent" }}
                  bgColor="transparent"
                  {...restProps}
                >
                  <VStack align="stretch" w="100%" h="100%" spacing={3}>
                    <Box
                      pos="relative"
                      overflow="hidden"
                      w="100%"
                      pt="60%"
                      borderRadius="lg"
                    >
                      <Image
                        pos="absolute"
                        top={0}
                        left={0}
                        w="100%"
                        h="100%"
                        userSelect="none"
                        objectFit="cover"
                        alt="illustration of the demo"
                        draggable="false"
                        fallback={
                          <Skeleton
                            pos="absolute"
                            top={0}
                            left={0}
                            w="100%"
                            h="100%"
                            endColor="backgroundQuaternary.600"
                            startColor="backgroundQuaternary.500"
                          />
                        }
                        src={coverUrl}
                      />
                    </Box>

                    <VStack align="stretch" flex={1} spacing={2}>
                      <HStack spacing={1}>
                        <Text color="textSecondary" size="body.sm">
                          {VIDEO_CAROUSEL_DIFFICULTY[difficulty].label}
                        </Text>
                        <Text color="textSecondary" size="body.sm">
                          •
                        </Text>
                        <Text color="textSecondary" size="body.sm">
                          {duration < 60
                            ? `${duration} sec`
                            : _.compact([
                                `${Math.floor(duration / 60)} min`,
                                String(duration % 60 || ""),
                              ]).join(" ")}
                        </Text>
                      </HStack>

                      <Text
                        overflow="hidden"
                        color="textPrimary"
                        textAlign="left"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        size="body.bold.md"
                      >
                        {title}
                      </Text>
                    </VStack>
                  </VStack>
                </VideoCarouselItem>
              )
            )}
      </Flex>

      {!isSkeleton && (
        <>
          <HStack
            pos="absolute"
            zIndex="docked"
            top={0}
            left={4}
            h="100%"
            visibility={isPrevScrollable ? "visible" : "hidden"}
          >
            <CarouselNavButton variant="prev" onClick={() => scrollPrev()} />
          </HStack>
          <HStack
            pos="absolute"
            zIndex="docked"
            top={0}
            right={4}
            h="100%"
            visibility={isNextScrollable ? "visible" : "hidden"}
          >
            <CarouselNavButton variant="next" onClick={() => scrollNext()} />
          </HStack>
        </>
      )}
    </Box>
  );
}

// ------------------------------------

type VideoCarouselItemProps = BoxProps;

function VideoCarouselItem({ children, ...props }: VideoCarouselItemProps) {
  return (
    <Box
      pos="relative"
      flex={[
        "0 0 100%",
        "0 0 100%",
        "0 0 50%",
        "0 0 33.333%",
        "0 0 33.333%",
        "0 0 25%",
      ]}
      pl={5}
    >
      <Box {...props} pos="relative" pt="60%" pb="52px">
        <Box pos="absolute" top={0} left={0} w="100%" h="100%">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
