import { useCallback, useMemo, useRef } from "react";
import { useRouter } from "next/router";
import isBreakpointMobile from "domains/commons/isMobile";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import { useInspirationItems } from "domains/home/hooks/useInspiration";
import { InferenceParams } from "domains/home/interfaces";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import _ from "lodash";
import {
  useContainerPosition,
  useMasonry,
  usePositioner,
  useResizeObserver,
  useScroller,
} from "masonic";

import { Box, Image, Skeleton, useBreakpoint, VStack } from "@chakra-ui/react";
import { useDebounce } from "@react-hook/debounce";
import useSize from "@react-hook/size";

interface SectionInspirationsProps {}

export default function SectionInspirations(_props: SectionInspirationsProps) {
  const { items } = useInspirationItems();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [containerWidth] = useSize(containerRef);
  const debouncedContainerWidth = useDebounce(containerWidth, 500);
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
    debouncedContainerWidth,
  ]);
  const { scrollTop, isScrolling } = useScroller(offset);
  const positioner = usePositioner({
    width,
    columnWidth: 280,
    columnGutter: 20,
  });
  const resizeObserver = useResizeObserver(positioner);

  const hasMore = false;
  const masonryItems = useMemo<MasonryCardProps["data"][]>(
    () =>
      items
        .filter((item) => !!item.asset)
        .map((item) => ({
          assetId: item.assetId,
          avatarUrl: item.asset?.url,
          avatarWidth: item.asset?.width || 512,
          avatarHeight: item.asset?.height || 512,
          params: item.params,
        })),
    [items]
  );

  const masonryContent = useMasonry({
    positioner,
    scrollTop,
    isScrolling,
    resizeObserver,
    items: masonryItems,
    className: "masonic",
    containerRef,
    itemKey: (data, idx) => data.assetId || `${idx}`,
    overscanBy: 2,
    height: windowHeight || 0,
    render: MasonryCard,
  });

  if (masonryItems.length === 0) return null;

  return (
    <Section
      title="Get Inspired"
      subTitle="Explore and remix curated examples."
      action={
        hasMore ? (
          <SectionAction>View All Inspirations</SectionAction>
        ) : undefined
      }
    >
      <Box ref={containerRef} sx={{ ".masonic": { outline: "none" } }} w="100%">
        {masonryContent}
      </Box>
    </Section>
  );
}

// ------------------------------------

type MasonryCardProps = {
  index: number;
  data: {
    assetId?: string;
    avatarUrl?: string;
    avatarWidth: number;
    avatarHeight: number;
    params?: InferenceParams;
  };
  width: number;
};

function MasonryCard({
  data: { avatarUrl, avatarWidth, avatarHeight, params, assetId },
  width,
}: MasonryCardProps) {
  const router = useRouter();
  const breakpoint = useBreakpoint();
  const isMobile = isBreakpointMobile(breakpoint);
  const avatarRatio = Math.round((avatarHeight / avatarWidth) * 100);
  const height = _.max([(width * avatarRatio) / 100, 170]);

  const handleRestartGenerationClick = useCallback(() => {
    void router.push({
      pathname: "/images/new",
      query: {
        ...params,
        onboarding: "1",
      },
    });
    Track(AnalyticsEvents.Home.ClickedRestartGeneration, {
      id: assetId,
    });
  }, [router, params, assetId]);

  return (
    <Box pos="relative" overflow="hidden" borderRadius="lg" data-group>
      <Image
        w={width}
        h={height}
        alt="inspiration image"
        fallback={
          <Skeleton
            w={width}
            h={height}
            endColor="backgroundQuaternary.600"
            startColor="backgroundQuaternary.500"
          />
        }
        fit="cover"
        src={avatarUrl}
      />

      {assetId && (
        <VStack
          pos="absolute"
          zIndex="docked"
          top={0}
          left={0}
          align="center"
          justify="center"
          w="100%"
          h="100%"
          _groupHover={{ visibility: "visible" }}
          visibility="hidden"
          bgColor="blackAlpha.500"
          spacing={4}
        >
          <Button
            variant="primary"
            borderRadius="30px"
            colorScheme="white"
            size="lg"
            rightIcon={<Icon id="Ui/Refresh" h="18px" />}
            onClick={handleRestartGenerationClick}
          >
            Restart Generation
          </Button>

          {!isMobile && (
            <Button
              variant="primary"
              borderRadius="30px"
              colorScheme="white"
              size="lg"
              rightIcon={<Icon id="Ui/Edit" h="18px" />}
              internalLink={
                `/canvas/new?importAssetId=${assetId}` as "/canvas/new"
              }
              onClick={() => {
                Track(AnalyticsEvents.Home.ClickedEditInCanvas, {
                  id: assetId,
                });
              }}
            >
              Edit in Canvas
            </Button>
          )}
        </VStack>
      )}
    </Box>
  );
}
