import { useMemo } from "react";
import { useUrlsByAssetIds } from "domains/assets/hooks/useAssetsByIds";
import { INSPIRATION_ITEMS } from "domains/home/constants";
import { InspirationItem } from "domains/home/interfaces";
import {
  getControlNetInfluenceFromString,
  getControlNetPresetFromString,
} from "domains/inference/enum/ControlNetPreset";
import _ from "lodash";

export function useInspirationItems() {
  const rawItems = useMemo(() => {
    return INSPIRATION_ITEMS?.map((item) => ({
      assetId: item.assetId,
      params: item.params,
    }));
  }, []);

  const assetIds = useMemo(() => {
    if (!rawItems) return [];
    const ids: string[] = [];
    for (const item of rawItems) {
      ids.push(item.assetId);
    }
    return ids;
  }, [rawItems]);

  const urlsByAssetIds = useUrlsByAssetIds(assetIds);

  const items: InspirationItem[] = useMemo(() => {
    if (!rawItems) return [];
    return rawItems.map((item) => {
      const assetUrl = urlsByAssetIds[item["assetId"]];
      return {
        assetId: item.assetId,
        params: _.omitBy(
          {
            prompt: item.params.prompt || undefined,
            negativePrompt: item.params.negativePrompt || undefined,
            generatorId: item.params.modelId,
            referenceAssetId: item.params.referenceAssetId,
            referenceComposition: item.params.modality
              ? getControlNetPresetFromString(item.params.modality || "")?.value
              : undefined,
            referenceInfluence: item.params.modality
              ? String(getControlNetInfluenceFromString(item.params.modality))
              : undefined,
            guidance:
              item.params.guidance !== undefined
                ? String(item.params.guidance)
                : undefined,
            samplingSteps:
              item.params.samplingSteps !== undefined
                ? String(item.params.samplingSteps)
                : undefined,
            height:
              item.params.height !== undefined
                ? String(item.params.height)
                : undefined,
            width:
              item.params.width !== undefined
                ? String(item.params.width)
                : undefined,
          },
          _.isUndefined
        ),
        asset: assetUrl
          ? {
              url: assetUrl,
              width: item.params.width || 512,
              height: item.params.height || 512,
            }
          : undefined,
      };
    });
  }, [rawItems, urlsByAssetIds]);

  return {
    items,
  };
}
