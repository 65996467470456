import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useQuickstartGenerator } from "domains/home/hooks/useQuickstart";
import {
  InferenceParams,
  QuickstartFormItemImage,
  QuickstartFormItemText,
} from "domains/home/interfaces";
import { mergeInferenceArgs } from "domains/home/utils";
import Button from "domains/ui/components/Button";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  CloseButton,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import SectionImage, { SectionImageProps } from "./SectionImage";
import SectionText, { SectionTextProps } from "./SectionText";

type QuickstartForm = {
  [key: string]: string | undefined;
};

export interface ModalQuickstartProps {
  isOpen: boolean;
  id?: string;
  onClose: () => void;
}

export default function ModalQuickstart({
  isOpen,
  id,
  onClose,
}: ModalQuickstartProps) {
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form, setForm] = useState<QuickstartForm | undefined>();
  const { generator } = useQuickstartGenerator(id);

  const isReady = !!form;
  const canSubmit = useMemo(() => {
    return (
      !!form &&
      Object.keys(form).reduce((memo, key) => {
        return memo && !!form?.[key];
      }, true)
    );
  }, [form]);

  const inferenceArgs = useMemo<InferenceParams>(() => {
    if (!form) return {};
    const args = Object.keys(form).map((key) => {
      const formItem = generator?.form.find(
        (generatorForm) => generatorForm.id === key
      );
      if (!formItem) return {};
      const selectedOption = formItem.options.find(
        (option) => option.id === form[key]
      );
      if (!selectedOption) return {};
      return selectedOption.value;
    });
    return mergeInferenceArgs(...args);
  }, [form, generator?.form]);

  // ----------------------------------

  const reactFormSelect = useCallback(
    (id: string, value: string) => {
      setForm({ ...form, [id]: value });
    },
    [setForm, form]
  );

  const submit = useCallback(() => {
    setIsSubmitting(true);
    void router.push({
      pathname: "/images/new",
      query: {
        ...inferenceArgs,
        onboarding: "1",
        auto: "1",
      },
    });
    Track(AnalyticsEvents.Home.ClickedGenerateStartFromHere, {
      ...form,
    });
  }, [setIsSubmitting, router, inferenceArgs, form]);

  // ----------------------------------

  const renderHandlers = {
    formSelect: useCallback(
      (id: string, value: string) => reactFormSelect(id, value),
      [reactFormSelect]
    ),
    submitClick: useCallback(() => submit(), [submit]),
  };

  // ----------------------------------

  useEffect(() => {
    if (!generator) {
      setForm(undefined);
    } else {
      setForm(
        generator.form.reduce<QuickstartForm>((memo, obj) => {
          memo[obj.id] = undefined;
          return memo;
        }, {})
      );
    }
  }, [generator, setForm]);

  // ----------------------------------

  return (
    <Modal isOpen={isOpen && isReady} onClose={onClose} size={["full", "xl"]}>
      <ModalOverlay />
      <ModalContent bgColor="backgroundTertiary.500">
        <ModalBody minH="500px" p={0}>
          <HStack
            align="center"
            justify="space-between"
            w="100%"
            px={6}
            py={4}
            borderColor="border.500"
            borderBottomWidth={1}
            spacing={2}
          >
            <Text size="title.sm">Create Consistent {generator?.title}</Text>
            <CloseButton m={-2} onClick={onClose} />
          </HStack>

          <VStack p={6} spacing={6}>
            {generator?.form.map((formItem) => (
              <FormSection
                key={formItem.id}
                value={form?.[formItem.id] || undefined}
                textData={formItem.type === "text" ? formItem : undefined}
                imageData={formItem.type === "image" ? formItem : undefined}
                onSelect={renderHandlers.formSelect.bind(null, formItem.id)}
              />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter pt={0} pb={6}>
          <Button
            w="100%"
            size="lg"
            data-testid="submit-button"
            isDisabled={!canSubmit}
            isLoading={isSubmitting}
            onClick={renderHandlers.submitClick}
          >
            Generate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

type FormSectionProps = {
  textData?: QuickstartFormItemText;
  imageData?: QuickstartFormItemImage;
  value?: string;
  onSelect: (value: string, inferenceArgs: InferenceParams) => void;
};

function FormSection({
  value,
  textData,
  imageData,
  onSelect,
}: FormSectionProps) {
  const textOptions = useMemo<SectionTextProps["options"] | undefined>(() => {
    if (!textData?.options) return undefined;
    return textData.options.map((option) => ({
      label: option.label,
      value: option.id,
    }));
  }, [textData?.options]);
  const imageOptions = useMemo<SectionImageProps["options"] | undefined>(() => {
    if (!imageData?.options) return [];
    return imageData.options.map((option) => ({
      label: option.label,
      avatarUrl: option.avatarUrl,
      value: option.id,
    }));
  }, [imageData?.options]);

  const handleSelect = useCallback(
    (value: string) => {
      const selectedOption = (
        textData?.options ||
        imageData?.options ||
        []
      ).find((option) => option.id === value);
      if (!selectedOption) return;
      onSelect(value, selectedOption.value);
    },
    [textData?.options, imageData?.options, onSelect]
  );

  if (textData && textOptions) {
    return (
      <SectionText
        title={textData.label}
        value={value}
        options={textOptions}
        onOptionSelect={handleSelect}
      />
    );
  } else if (imageData && imageOptions) {
    return (
      <SectionImage
        title={imageData.label}
        value={value}
        options={imageOptions}
        onOptionSelect={handleSelect}
      />
    );
  } else {
    return null;
  }
}
