import { NextSeo } from "next-seo";
import SectionInspirations from "domains/home/components/SectionInspirations";
import SectionQuickstart from "domains/home/components/SectionQuickstart";
import SectionRecentImages from "domains/home/components/SectionRecentImages";
import SectionTools from "domains/home/components/SectionTools";
import SectionTop from "domains/home/components/SectionTop";
import SectionTutorials from "domains/home/components/SectionTutorials";
import { useIntl } from "react-intl";

import { VStack } from "@chakra-ui/react";

export default function Home() {
  const intl = useIntl();

  return (
    <>
      <NextSeo
        title={intl.formatMessage({
          id: "pages.index.title",
          defaultMessage: "Home",
        })}
      />

      <VStack
        align="stretch"
        w="100%"
        maxW="1650px"
        mx="auto"
        pt={6}
        pb={12}
        px={9}
        spacing={12}
      >
        <SectionTop />
        <SectionQuickstart />
        <SectionTools />
        <SectionRecentImages />
        <SectionTutorials />
        <SectionInspirations />
      </VStack>
    </>
  );
}
