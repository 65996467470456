import React from "react";

import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

export interface PreviewModalVideoProps {
  isOpen: boolean;
  youtubeLink?: string;
  onClose: () => void;
}

export default function PreviewModalVideo({
  isOpen,
  youtubeLink,
  onClose,
}: PreviewModalVideoProps) {
  return (
    <Modal
      isOpen={isOpen && !!youtubeLink}
      onClose={onClose}
      size="6xl"
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent
        justifyContent="center"
        maxW="960px"
        p={0}
        bg="none"
        border="none"
      >
        <ModalBody flex={0} w="100%">
          <AspectRatio ratio={16 / 9}>
            <iframe
              src={`${youtubeLink}?autoplay=1`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
