import { useMemo } from "react";
import { useUrlsByAssetIds } from "domains/assets/hooks/useAssetsByIds";
import { QUICKSTART_ITEMS } from "domains/home/constants";
import { QuickstartGenerator, QuickstartItem } from "domains/home/interfaces";
import _ from "lodash";

export function useQuickstartItems() {
  const rawItems = useMemo(() => {
    return QUICKSTART_ITEMS?.map((item) => ({
      id: item.id,
      title: item.title,
      avatarAssetId: item.avatar_asset_id,
    }));
  }, []);

  const assetIds = useMemo(() => {
    if (!rawItems) return [];
    const ids: string[] = [];
    for (const item of rawItems) {
      ids.push(item.avatarAssetId);
    }
    return ids;
  }, [rawItems]);

  const urlsByAssetIds = useUrlsByAssetIds(assetIds);

  const items: QuickstartItem[] = useMemo(() => {
    if (!rawItems) return [];
    return rawItems.map((item) => ({
      ..._.omit(item, ["avatarAssetId"]),
      avatarUrl: urlsByAssetIds[item["avatarAssetId"]] || undefined,
    }));
  }, [rawItems, urlsByAssetIds]);

  return {
    items,
  };
}

export function useQuickstartGenerator(id: string | undefined) {
  const rawItem = useMemo(() => {
    if (!id) return;
    const dataItem = QUICKSTART_ITEMS?.find((item) => item.id === id);
    if (!dataItem) return;
    return {
      id: dataItem.id,
      title: dataItem.title,
      form: dataItem.form.map((formItem) => ({
        ...formItem,
        options: formItem.options.map((option) => ({
          id: option.id,
          label: option.label,
          ...(option.avatar_asset_id
            ? { avatarAssetId: option.avatar_asset_id }
            : {}),
          value: option.params,
        })),
      })),
    };
  }, [id]);

  const assetIds = useMemo(() => {
    if (!rawItem) return [];
    const ids: string[] = [];
    for (const formItem of rawItem.form) {
      for (const option of formItem.options) {
        if (option.avatarAssetId) {
          ids.push(option.avatarAssetId);
        }
      }
    }
    return ids;
  }, [rawItem]);

  const urlsByAssetIds = useUrlsByAssetIds(assetIds);

  const generator: QuickstartGenerator | undefined = useMemo(() => {
    if (!rawItem) return;
    return {
      ...rawItem,
      form: rawItem.form.map((formItem) => ({
        ...formItem,
        type: ({
          text: "text",
          image: "image",
        }[formItem.type] || "text") as "text" | "image",
        options: formItem.options.map((option) => ({
          ..._.omit(option, ["avatarAssetId"]),
          ...(option.avatarAssetId
            ? { avatarUrl: urlsByAssetIds[option.avatarAssetId] || undefined }
            : {}),
        })),
      })),
    };
  }, [rawItem, urlsByAssetIds]);

  return {
    generator,
  };
}
