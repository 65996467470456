import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useSidebarContext } from "domains/navigation/contexts/SidebarProvider";
import Button, { ButtonProps } from "domains/ui/components/Button";
import { CarouselNavButton } from "domains/ui/components/Carousel";
import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";
import _, { debounce } from "lodash";

import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";

type AssetCarouselItem = Omit<
  BoxProps & ButtonProps,
  "id" | "title" | "avatarUrl"
> & {
  id: string;
  title?: React.ReactNode;
  avatarUrl?: string;
};

export interface AssetCarouselProps {
  items: AssetCarouselItem[];
  skeleton?: boolean;
  hideTitle?: boolean;
}

export default function AssetCarousel({
  items,
  skeleton: isSkeleton,
  hideTitle: isTitleHidden,
}: AssetCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    skipSnaps: true,
  });
  const [isPrevScrollable, setIsPrevScrollable] = useState<boolean>(false);
  const [isNextScrollable, setIsNextScrollable] = useState<boolean>(false);

  // ----------------------------------

  const scrollNext = () => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();
    const slidesInViewCount = emblaApi.slidesInView().length - 1 || 1;
    const nextIndex = currentIndex + Math.round(slidesInViewCount * 0.8);
    emblaApi.scrollTo(nextIndex);
  };

  const scrollPrev = () => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();
    const slidesInViewCount = emblaApi.slidesInView().length - 1 || 1;
    const nextIndex = currentIndex - Math.round(slidesInViewCount * 0.8);
    emblaApi.scrollTo(nextIndex);
  };

  const updateScrollable = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setIsPrevScrollable(emblaApi.canScrollPrev());
      setIsNextScrollable(emblaApi.canScrollNext());
    },
    [setIsPrevScrollable, setIsNextScrollable]
  );

  const updateScrollableWithDebounce = useMemo(
    () => debounce(updateScrollable, 500),
    [updateScrollable]
  );

  useEffect(() => {
    if (!emblaApi) return;
    updateScrollable(emblaApi);
    emblaApi.on("select", updateScrollable);
    emblaApi.on("resize", updateScrollableWithDebounce);
    return () => {
      if (!emblaApi) return;
      emblaApi.off("select", updateScrollable);
      emblaApi.off("resize", updateScrollableWithDebounce);
    };
  }, [emblaApi, updateScrollable, updateScrollableWithDebounce]);

  return (
    <Box
      ref={!isSkeleton ? emblaRef : undefined}
      pos="relative"
      overflow="hidden"
      mx={-9}
      px={9}
    >
      <Flex
        sx={{
          backfaceVisibility: "hidden",
          touchAction: "pan-y",
        }}
        align="stretch"
        ml={-5}
      >
        {isSkeleton
          ? _.range(6).map((index) => (
              <AssetCarouselItem
                withTitle={!isTitleHidden}
                key={`skeleton-${index}`}
              >
                <Skeleton w="100%" h="100%" borderRadius="md" />
              </AssetCarouselItem>
            ))
          : items.map(({ id, title, avatarUrl, ...restProps }, index) => (
              <AssetCarouselItem
                key={`item-${id}-${index}`}
                w="100%"
                cursor="pointer"
                _hover={{ opacity: 0.8 }}
                _active={{ opacity: 0.9 }}
                withTitle={!isTitleHidden}
                {...restProps}
              >
                <VStack
                  align="stretch"
                  w="100%"
                  h="100%"
                  bgColor="backgroundSecondary.500"
                  spacing={0}
                >
                  <Box pos="relative" w="100%" pt="100%">
                    <Image
                      pos="absolute"
                      top={0}
                      left={0}
                      w="100%"
                      h="100%"
                      userSelect="none"
                      objectFit="cover"
                      alt="illustration of the demo"
                      draggable="false"
                      fallback={
                        <Skeleton
                          pos="absolute"
                          top={0}
                          left={0}
                          w="100%"
                          h="100%"
                          endColor="backgroundQuaternary.600"
                          startColor="backgroundQuaternary.500"
                        />
                      }
                      src={avatarUrl}
                    />
                  </Box>

                  {!isTitleHidden && (
                    <VStack justifyContent="center" flex={1} px={3}>
                      <Text
                        overflow="hidden"
                        textAlign="center"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        size="body.bold.md"
                      >
                        {title}
                      </Text>
                    </VStack>
                  )}
                </VStack>
              </AssetCarouselItem>
            ))}
      </Flex>

      {!isSkeleton && (
        <>
          <HStack
            pos="absolute"
            zIndex="docked"
            top={0}
            left={4}
            h="100%"
            visibility={isPrevScrollable ? "visible" : "hidden"}
          >
            <CarouselNavButton variant="prev" onClick={() => scrollPrev()} />
          </HStack>
          <HStack
            pos="absolute"
            zIndex="docked"
            top={0}
            right={4}
            h="100%"
            visibility={isNextScrollable ? "visible" : "hidden"}
          >
            <CarouselNavButton variant="next" onClick={() => scrollNext()} />
          </HStack>
        </>
      )}
    </Box>
  );
}

// ------------------------------------

type AssetCarouselItemProps = BoxProps & {
  withTitle?: boolean;
};

function AssetCarouselItem({
  children,
  withTitle,
  ...props
}: AssetCarouselItemProps) {
  const { isOpen: isSidebarOpen } = useSidebarContext();
  return (
    <Box
      pos="relative"
      flex={[
        "0 0 50%",
        "0 0 33.333%",
        isSidebarOpen ? "0 0 33.333%" : "0 0 25%",
        isSidebarOpen ? "0 0 25%" : "0 0 20%",
        isSidebarOpen ? "0 0 20%" : "0 0 16.666%",
        isSidebarOpen ? "0 0 16.666%" : "0 0 14.286%",
      ]}
      pl={5}
    >
      <Box
        as={Button}
        display="block"
        variant="unstyled"
        {...props}
        pos="relative"
        overflow="hidden"
        pt="100%"
        pb={withTitle ? "40px" : undefined}
        borderRadius="lg"
      >
        <Box pos="absolute" top={0} left={0} w="100%" h="100%">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
