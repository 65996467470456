import { useCallback, useMemo, useState } from "react";
import AssetZoom from "domains/assets/components/AssetZoom";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useUser } from "domains/user/hooks/useUser";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useGetAssetsQuery } from "infra/api/generated/api";

import AssetCarousel, { AssetCarouselProps } from "../ElementAssetCarousel";

// TODO: Check cache management

interface SectionRecentImagesProps {}

export default function SectionRecentImages(_props: SectionRecentImagesProps) {
  const { selectedTeam } = useTeamContext();
  const { nsfwFilteredTypes } = useUser();
  const { data, isLoading } = useGetAssetsQuery({
    teamId: selectedTeam.id,
    pageSize: "20",
    type: "inference",
  });

  const [revealed, setRevealed] = useState<string[]>([]);
  const handleReveal = useCallback(
    (id: string) => {
      if (revealed.includes(id)) {
        setRevealed(revealed.filter((item) => item !== id));
      } else {
        setRevealed([...revealed, id]);
      }
    },
    [revealed]
  );

  const isSkeleton = isLoading;
  const files = useMemo(
    () => mapAssetsToImagesFiles(data?.assets ?? [], nsfwFilteredTypes),
    [data?.assets, nsfwFilteredTypes]
  );
  const items = useMemo<AssetCarouselProps["items"]>(
    () =>
      files.map((file) => ({
        id: file.id,
        avatarUrl: file.thumbnail,
        internalLink: `/?openAssetId=${file.id}` as "/",
        onClick: () => {
          Track(AnalyticsEvents.Home.ClickedImage, {
            id: file.id,
          });
        },
      })) ?? [],
    [files]
  );

  if (!isSkeleton && items.length === 0) return null;

  return (
    <>
      <Section
        title="Recent Generations"
        action={
          !isSkeleton ? (
            <SectionAction
              internalLink="/images"
              onClick={() => {
                Track(AnalyticsEvents.Home.ClickedViewAllImages);
              }}
            >
              View All
            </SectionAction>
          ) : undefined
        }
      >
        <AssetCarousel hideTitle items={items} skeleton={isSkeleton} />
      </Section>

      <AssetZoom
        assets={files}
        hasMore={false}
        loadMore={() => {}}
        revealed={revealed}
        onReveal={handleReveal}
      />
    </>
  );
}
