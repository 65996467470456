import { useEffect, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePostAssetGetBulkMutation } from "infra/api/generated/api";

export function useUrlsByAssetIds(assetIds: string[]) {
  const { selectedTeam } = useTeamContext();
  const [getBulkAssetTrigger] = usePostAssetGetBulkMutation();
  const [assetUrls, setAssetUrls] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (assetIds.length === 0) {
      setAssetUrls({});
      return;
    }

    const timeout = setTimeout(async () => {
      const { assets } = await getBulkAssetTrigger({
        teamId: selectedTeam.id,
        body: { assetIds },
      }).unwrap();
      setAssetUrls(
        assets.reduce<{ [key: string]: string }>((memo, asset) => {
          memo[asset.id] = `${asset.url}&format=jpeg&quality=80`;
          return memo;
        }, {})
      );
    }, 100);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [assetIds, setAssetUrls, getBulkAssetTrigger, selectedTeam.id]);

  return assetUrls;
}
