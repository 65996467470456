import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";

import { Box, BoxProps, Flex, HStack, Text, VStack } from "@chakra-ui/react";

type BannerItem = {
  id: string;
  title: string;
  subTitle: string;
  backgroundProps: BoxProps;
};

const BANNER_ITEMS: BannerItem[] = [
  {
    id: "start",
    title: "Craft Distinctive Game Art",
    subTitle: "Enjoy the most comprehensive AI toolkit for game creators.",
    backgroundProps: {
      bgImage: 'url("/home/top-banner-craft-distinctive-game-art.png")',
      bgSize: "708px auto",
      bgPosition: [
        "center top 40%",
        "center top 40%",
        "left 100px top 40%",
        "left 100px top 40%",
        "right -70px top 40%",
      ],
      sx: {
        opacity: [0.3, 0.3, 1],
      },
    },
  },
  {
    id: "start2",
    title: "Infinite Game Models at Your Fingertips",
    subTitle:
      "Achieve unmatched consistency with custom-trained AI generators.",
    backgroundProps: {
      bgImage: 'url("/home/top-banner-infinite-game-model.png")',
      bgSize: "1098px auto",
      bgPosition: [
        "left 60% top -100px",
        "left 60% top -100px",
        "left 0% top -100px",
        "left 0% top -100px",
        "left 0% top -100px",
        "right -100px top -100px",
      ],
      sx: {
        opacity: [0.3, 0.3, 1],
        maskImage: [
          undefined,
          undefined,
          "linear-gradient(to right, transparent 0%, black 20%)",
        ],
      },
    },
  },
  {
    id: "start3",
    title: "Generate Consistent Assets",
    subTitle: "Create seamless variations, tailored to your vision.",
    backgroundProps: {
      bgImage: 'url("/home/top-banner-consistent-assets.png")',
      bgSize: "918px auto",
      bgPosition: [
        "left 60% center",
        "left 60% center",
        "left 120px center",
        "left 120px center",
        "left 190px center",
        "right -100px center",
      ],
      sx: {
        opacity: [0.3, 0.3, 1],
        maskImage: [
          undefined,
          undefined,
          "linear-gradient(to right, transparent 30%, black 75%)",
          "linear-gradient(to right, transparent 25%, black 75%)",
          "linear-gradient(to right, transparent 25%, black 75%)",
          "linear-gradient(to right, transparent 25%, black 55%)",
        ],
      },
    },
  },
  {
    id: "start4",
    title: "Render Sketches to Perfection",
    subTitle: "Instantly apply diverse styles to any sketch or concept.",
    backgroundProps: {
      bgImage: 'url("/home/top-banner-render-perfect-sketches.png")',
      bgSize: "479px auto",
      bgPosition: [
        "center top 30%",
        "center top 30%",
        "left 100px top 30%",
        "left 100% top 30%",
        "left 80% top 30%",
      ],
      sx: {
        opacity: [0.3, 0.3, 1],
        maskImage: [
          undefined,
          undefined,
          "linear-gradient(to right, transparent 30%, black 60%)",
          "linear-gradient(to right, transparent 25%, black 40%)",
          "unset",
        ],
      },
    },
  },
];

export default function SectionTop() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start", loop: true });
  const [scrollIndex, setScrollIndex] = useState<number>(0);
  const [isAutoScroll, setIsAutoScroll] = useState<boolean>(true);
  const items = BANNER_ITEMS;

  // ----------------------------------

  const updateScrollIndex = useCallback(
    (emblaApi: EmblaCarouselType) => {
      const scrollIndex = emblaApi.selectedScrollSnap() || 0;
      setScrollIndex(scrollIndex);
    },
    [setScrollIndex]
  );

  const goTo = useCallback(
    (index: number) => {
      emblaApi?.scrollTo(index);
    },
    [emblaApi]
  );

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  // ----------------------------------

  const handleContainerMouseEnter = useCallback(() => {
    setIsAutoScroll(false);
  }, [setIsAutoScroll]);

  const handleContainerMouseLeave = useCallback(() => {
    setIsAutoScroll(true);
  }, [setIsAutoScroll]);

  // ----------------------------------

  useEffect(() => {
    if (!emblaApi) return;
    updateScrollIndex(emblaApi);
    emblaApi.on("select", updateScrollIndex);
    return () => {
      emblaApi?.off("select", updateScrollIndex);
    };
  }, [emblaApi, updateScrollIndex]);

  useEffect(() => {
    if (!isAutoScroll) return;
    const interval = setInterval(() => {
      if (!document.hasFocus()) return;
      scrollNext();
    }, 4000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isAutoScroll, scrollNext]);

  return (
    <Box
      ref={emblaRef}
      pos="relative"
      overflow="hidden"
      w="100%"
      h="260px"
      borderRadius="lg"
      onMouseEnter={handleContainerMouseEnter}
      onMouseLeave={handleContainerMouseLeave}
    >
      <Flex
        sx={{
          backfaceVisibility: "hidden",
        }}
        align="stretch"
      >
        {items.map(({ id, ...restProps }) => (
          <BannerItem {...restProps} key={`${id}`} />
        ))}
      </Flex>

      <HStack
        pos="absolute"
        zIndex="docked"
        bottom={[6, 10, 10]}
        left={[6, 10, 10]}
        spacing={1}
      >
        {items.map(({ id }, index) => (
          <Box
            key={`${id}`}
            py={2}
            cursor="pointer"
            data-group
            onClick={() => goTo(index)}
          >
            <Box
              w="18px"
              h="4px"
              borderRadius="2px"
              _groupHover={{ bgColor: "textPrimary" }}
              bgColor={scrollIndex === index ? "textPrimary" : "whiteAlpha.200"}
            />
          </Box>
        ))}
      </HStack>
    </Box>
  );
}

// ------------------------------------

type BannerItemProps = {
  title: string;
  subTitle: string;
  backgroundProps: BoxProps;
};

function BannerItem({ title, subTitle, backgroundProps }: BannerItemProps) {
  return (
    <Box pos="relative" flex="0 0 100%" overflow="hidden" h="260px">
      <VStack
        pos="absolute"
        zIndex="docked"
        top={0}
        left={0}
        align="flex-start"
        justify="center"
        w={["100%", "100%", "75%"]}
        h="100%"
        pb={8}
        px={[8, 12, 12]}
        spacing={2}
      >
        <Text color="textPrimary" size={["title.md", "title.lg"]}>
          {title}
        </Text>
        <Text color="textPrimary" size={["body.md", "body.lg"]}>
          {subTitle}
        </Text>
      </VStack>

      <HStack
        pos="relative"
        w="100%"
        h="100%"
        bgSize="2px auto"
        bgPosition="top left"
        bgRepeat="repeat-x"
        bgColor="#202020"
        spacing={0}
      >
        <Box flex={1} maxW={[0, 0, "360px"]} />
        <Box flex={2} h="100%" bgRepeat="no-repeat" {...backgroundProps} />
      </HStack>
    </Box>
  );
}
