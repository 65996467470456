import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon, { IconProps } from "domains/ui/components/Icon";

interface CarouselNavButtonProps extends Omit<ButtonProps, "variant"> {
  variant: "prev" | "next";
}

export function CarouselNavButton({
  variant,
  ...props
}: CarouselNavButtonProps) {
  const variantProps: {
    buttonProps: ButtonProps;
    iconId: IconProps["id"];
  } = (() => {
    switch (variant) {
      case "prev":
        return { buttonProps: { pr: "2px" }, iconId: "Ui/ChevronLeft" };
      case "next":
        return { buttonProps: { pl: "2px" }, iconId: "Ui/ChevronRight" };
    }
  })();

  return (
    <Button
      variant="unstyled"
      bgColor="background.500"
      borderWidth={1}
      borderColor="border.500"
      w="40px"
      h="40px"
      borderRadius="full"
      _hover={{ bgColor: "backgroundSecondary.500" }}
      _active={{ bgColor: "backgroundSecondary.800" }}
      mb="40px"
      pb="2px"
      {...variantProps.buttonProps}
      {...props}
    >
      <Icon id={variantProps.iconId} />
    </Button>
  );
}
