import Button from "domains/ui/components/Button";

import { Box, Checkbox, Heading, VStack } from "@chakra-ui/react";

export type SectionTextProps = {
  title: string;
  value?: string;
  options: {
    label: string;
    value: string;
  }[];
  onOptionSelect: (value: string) => void;
};

export default function SectionText({
  title,
  value,
  options,
  onOptionSelect,
}: SectionTextProps) {
  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="xs">
        {title}
      </Heading>

      <VStack align="stretch" spacing={1}>
        {options.map((option) => {
          const isChecked = option.value === value;
          return (
            <Button
              key={option.value}
              variant="inputSelect"
              size="lg"
              fontSize={["14px", "16px"]}
              data-testid={option.value}
              leftIcon={<Checkbox isChecked={isChecked} variant="circular" />}
              onClick={() => onOptionSelect(option.value)}
              colorScheme={isChecked ? "secondary" : undefined}
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                {option.label}
              </Box>
            </Button>
          );
        })}
      </VStack>
    </VStack>
  );
}
