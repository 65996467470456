import Button from "domains/ui/components/Button";

import {
  Box,
  Checkbox,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";

export type SectionImageProps = {
  title: string;
  value?: string;
  options: {
    label: string;
    avatarUrl?: string;
    value: string;
  }[];
  onOptionSelect: (value: string) => void;
};

export default function SectionImage({
  title,
  value,
  options,
  onOptionSelect,
}: SectionImageProps) {
  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="xs">
        {title}
      </Heading>

      <HStack spacing={2}>
        {options.map((option, index) => {
          const isChecked = option.value === value;
          return (
            <Box key={`item-${option.value}-${index}`} pos="relative" flex={1}>
              <Box
                as={Button}
                pos="relative"
                overflow="hidden"
                w="100%"
                pt="100%"
                pb="40px"
                borderWidth={1}
                borderColor="border.500"
                borderRadius="lg"
                bgColor="background.500 !important"
                data-group
                onClick={() => onOptionSelect(option.value)}
                outlineColor={isChecked ? "primary.500" : undefined}
                outlineOffset="-1px"
              >
                <Box
                  pos="absolute"
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  _groupHover={{ opacity: 0.8 }}
                  _groupActive={{ opacity: 0.9 }}
                >
                  <VStack align="stretch" w="100%" h="100%" spacing={0}>
                    <Box pos="relative" w="100%" pt="100%">
                      <Image
                        pos="absolute"
                        top={0}
                        left={0}
                        w="100%"
                        h="100%"
                        userSelect="none"
                        objectFit="cover"
                        alt="illustration of the demo"
                        draggable="false"
                        fallback={
                          <Skeleton
                            pos="absolute"
                            top={0}
                            left={0}
                            w="100%"
                            h="100%"
                            endColor="backgroundQuaternary.600"
                            startColor="backgroundQuaternary.500"
                          />
                        }
                        src={option.avatarUrl}
                      />
                    </Box>
                    <HStack justify="center" flex={1} px={3}>
                      <Text
                        overflow="hidden"
                        fontWeight="500"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        size="body.bold.md"
                      >
                        {option.label}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>

                <Checkbox
                  sx={
                    !isChecked
                      ? {
                          "& > .chakra-checkbox__control": {
                            bgColor: "backgroundTertiary.500",
                          },
                        }
                      : undefined
                  }
                  pos="absolute"
                  top={2}
                  left={2}
                  isChecked={isChecked}
                  variant="circular"
                />
              </Box>
            </Box>
          );
        })}
      </HStack>
    </VStack>
  );
}
