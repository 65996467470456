import { useCallback, useMemo, useState } from "react";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import { useTutorialItems } from "domains/home/hooks/useTutorials";
import PreviewModalVideo from "domains/ui/components/PreviewModalVideo";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import _ from "lodash";

import VideoCarousel, { VideoCarouselProps } from "../ElementVideoCarousel";

interface SectionTutorialsProps {}

export default function SectionTutorials(_props: SectionTutorialsProps) {
  const { items } = useTutorialItems();
  const [previewModalVideoYoutubeLink, setPreviewModalVideoYoutubeLink] =
    useState<string>();

  const hasMore = false;

  const handleItemClick = useCallback(
    (youtubeEmbedUrl: string) => {
      setPreviewModalVideoYoutubeLink(youtubeEmbedUrl);
    },
    [setPreviewModalVideoYoutubeLink]
  );

  const handlePreviewModalVideoClose = useCallback(() => {
    setPreviewModalVideoYoutubeLink(undefined);
  }, [setPreviewModalVideoYoutubeLink]);

  const carouselItems = useMemo<VideoCarouselProps["items"]>(
    () =>
      items.map((item) => ({
        ..._.omit(item, ["youtubeEmbedUrl"]),
        onClick: () => {
          handleItemClick(item.youtubeEmbedUrl);
          Track(AnalyticsEvents.Home.ClickedTutorial, {
            id: item.id,
          });
        },
      })),
    [handleItemClick, items]
  );

  return (
    <>
      <Section
        title="Tutorials"
        subTitle="Bite-sized learning content for immediate results."
        action={
          hasMore ? (
            <SectionAction>View All Tutorials</SectionAction>
          ) : undefined
        }
      >
        <VideoCarousel items={carouselItems} />
      </Section>

      <PreviewModalVideo
        isOpen={!!previewModalVideoYoutubeLink}
        youtubeLink={previewModalVideoYoutubeLink}
        onClose={handlePreviewModalVideoClose}
      />
    </>
  );
}
