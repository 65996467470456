import _ from "lodash";

import { InferenceParams } from "./interfaces";

export function mergeInferenceArgs(...args: InferenceParams[]) {
  return args.reduce((memo, rightArgs) => {
    return mergeTwoInferenceArgs(memo, rightArgs);
  }, {});
}

export function mergeTwoInferenceArgs(
  prevArgs: InferenceParams,
  args: InferenceParams
) {
  const {
    prompt: prevPrompt,
    negativePrompt: prevNegativePrompt,
    ...restPrevArgs
  } = prevArgs;
  const { prompt, negativePrompt, ...restArgs } = args;
  const mergedPrompt = _.compact([prevPrompt, prompt]).join(",") || undefined;
  const mergedNegativePrompt =
    _.compact([prevNegativePrompt, negativePrompt]).join(",") || undefined;
  const mergedRestArgs = { ...restPrevArgs, ...restArgs };
  return _.omitBy(
    {
      prompt: mergedPrompt,
      negativePrompt: mergedNegativePrompt,
      ...mergedRestArgs,
    },
    _.isUndefined
  );
}
