import { useCallback, useMemo, useState } from "react";
import ModalQuickstart from "domains/home/components/ModalQuickstart";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import { useQuickstartItems } from "domains/home/hooks/useQuickstart";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import AssetCarousel, { AssetCarouselProps } from "../ElementAssetCarousel";

interface SectionQuickstartProps {}

export default function SectionQuickstart({}: SectionQuickstartProps) {
  const { items } = useQuickstartItems();
  const [modalQuickstartId, setModalId] = useState<string>();

  const hasMore = false;

  const handleModalClose = useCallback(() => {
    setModalId(undefined);
  }, [setModalId]);

  const carouselItems = useMemo<AssetCarouselProps["items"]>(
    () =>
      items.map((item) => ({
        ...item,
        onClick: () => {
          setModalId(item.id);
          Track(AnalyticsEvents.Home.ClickedStartFromHere, {
            id: item.id,
          });
        },
      })),
    [items, setModalId]
  );

  return (
    <>
      <Section
        title="Start From Here"
        subTitle="Consistent visuals with just a few clicks."
        action={hasMore ? <SectionAction>View All</SectionAction> : undefined}
      >
        <AssetCarousel items={carouselItems} />
      </Section>

      <ModalQuickstart
        isOpen={!!modalQuickstartId}
        id={modalQuickstartId}
        onClose={handleModalClose}
      />
    </>
  );
}
