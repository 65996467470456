import { useMemo } from "react";
import { useUrlsByAssetIds } from "domains/assets/hooks/useAssetsByIds";
import {
  TUTORIAL_ITEMS,
  VIDEO_CAROUSEL_DIFFICULTY,
} from "domains/home/constants";
import { TutorialItem, VideoCarouselDifficulty } from "domains/home/interfaces";
import _ from "lodash";

export function useTutorialItems() {
  const rawItems = useMemo(() => {
    return TUTORIAL_ITEMS?.map((item) => ({
      id: item.id,
      title: item.title,
      difficulty: item.difficulty,
      duration: item.duration,
      coverAssetId: item.cover_asset_id,
      youtubeEmbedUrl: item.youtube_embed_url,
    }));
  }, []);

  const assetIds = useMemo(() => {
    if (!rawItems) return [];
    const ids: string[] = [];
    for (const item of rawItems) {
      ids.push(item.coverAssetId);
    }
    return ids;
  }, [rawItems]);

  const urlsByAssetIds = useUrlsByAssetIds(assetIds);

  const items: TutorialItem[] = useMemo(() => {
    if (!rawItems) return [];
    return rawItems.map((item) => ({
      ..._.omit(item, ["coverAssetId"]),
      difficulty: Object.keys(VIDEO_CAROUSEL_DIFFICULTY).includes(
        item.difficulty
      )
        ? (item.difficulty as VideoCarouselDifficulty)
        : "beginner",
      coverUrl: urlsByAssetIds[item["coverAssetId"]] || undefined,
    }));
  }, [rawItems, urlsByAssetIds]);

  return {
    items,
  };
}
