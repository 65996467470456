import inspirationFile from "domains/home/constants/inspiration.json";
import quickstartFile from "domains/home/constants/quickstart.json";
import tutorialsFile from "domains/home/constants/tutorials.json";
import { VideoCarouselDifficultyMap } from "domains/home/interfaces";

type InspirationFile = {
  data: {
    assetId: string;
    params: {
      prompt?: string;
      negativePrompt?: string;
      generatorId?: string;
      modelId?: string;
      referenceAssetId?: string;
      modality?: string;
      guidance?: number;
      samplingSteps?: number;
      height?: number;
      width?: number;
    };
  }[];
};

export const INSPIRATION_ITEMS =
  inspirationFile.data as InspirationFile["data"];

// ------------------------------------

export type QuickstartFile = {
  data: {
    id: string;
    title: string;
    avatar_asset_id: string;
    form: {
      id: string;
      label: string;
      type: string;
      options: {
        id: string;
        label: string;
        avatar_asset_id?: string;
        params: {
          prompt?: string;
          negativePrompt?: string;
          generatorId?: string;
          referenceAssetId?: string;
          referenceComposition?: string;
          referenceInfluence?: string;
          guidance?: string;
          width?: string;
          height?: string;
        };
      }[];
    }[];
  }[];
};

export const QUICKSTART_ITEMS = quickstartFile.data as QuickstartFile["data"];

// ------------------------------------

export type TutorialsFile = {
  data: {
    id: string;
    title: string;
    difficulty: "beginner" | "intermediate" | "advanced";
    duration: number;
    cover_asset_id: string;
    youtube_embed_url: string;
  }[];
};

export const TUTORIAL_ITEMS = tutorialsFile.data as TutorialsFile["data"];

// ------------------------------------

export const VIDEO_CAROUSEL_DIFFICULTY: VideoCarouselDifficultyMap = {
  beginner: { label: "Beginner" },
  intermediate: { label: "Intermediate" },
  advanced: { label: "Advanced" },
};
