import { useMemo } from "react";
import isBreakpointMobile from "domains/commons/isMobile";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon, { IconProps } from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Box, SimpleGrid, Text, useBreakpoint } from "@chakra-ui/react";

export type ToolItem = Omit<
  ButtonProps,
  "id" | "label" | "leftIcon" | "rightIcon"
> & {
  id?: string;
  label: React.ReactNode;
  primaryIconId?: IconProps["id"];
  rightIconId?: IconProps["id"];
};

const TOOLS_ITEMS: ToolItem[] = [
  {
    id: "train",
    label: "Train",
    primaryIconId: "Nav/Generator/Solid",
    internalLink: "/generators/new",
  },
  {
    id: "generate",
    label: "Generate",
    primaryIconId: "Nav/Image/Solid",
    internalLink: "/images/new",
  },
  {
    id: "design",
    label: "Design",
    primaryIconId: "Nav/Canvas/Solid",
    internalLink: "/canvas/new",
  },
  {
    id: "pixelate",
    label: "Pixelate",
    primaryIconId: "Nav/Pixelate/Solid",
    internalLink: "/pixelate",
  },
  {
    id: "isolate",
    label: "Isolate",
    primaryIconId: "Nav/BgRemove/Solid",
    internalLink: "/background-remove",
  },
  {
    id: "upscale",
    label: "Upscale",
    primaryIconId: "Nav/Upscale/Solid",
    internalLink: "/upscale",
  },
];

interface SectionToolsProps {}

export default function SectionTools(_props: SectionToolsProps) {
  const breakpoint = useBreakpoint();
  const isMobile = isBreakpointMobile(breakpoint);
  const items = useMemo(
    () =>
      TOOLS_ITEMS.filter((tool) => {
        return !(tool.id === "design" && isMobile);
      }),
    [isMobile]
  );

  return (
    <Section
      title="AI Toolkit"
      action={
        <SectionAction
          internalLink="/tools"
          onClick={() => {
            Track(AnalyticsEvents.Home.ClickedViewAllTools);
          }}
        >
          View All
        </SectionAction>
      }
    >
      <SimpleGrid columns={[1, 2, 3, 4, 5, 6]} spacing={3}>
        {items.map(
          ({ id, label, primaryIconId, rightIconId, ...restProps }, idx) => (
            <Button
              key={id || `${idx}`}
              variant="secondary"
              w="100%"
              bg="whiteAlpha.50"
              borderRadius="xl"
              leftIcon={
                primaryIconId && (
                  <Icon
                    h="22px"
                    pl={1}
                    pr={3}
                    id={primaryIconId}
                    color="primary.500"
                    filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
                  />
                )
              }
              rightIcon={rightIconId && <Icon id={rightIconId} />}
              h="62px"
              {...restProps}
              onClick={() => {
                Track(AnalyticsEvents.Home.ClickedTool, {
                  tool: id,
                });
              }}
            >
              <Box
                flex="1"
                overflow="hidden"
                textAlign="left"
                textOverflow={"ellipsis"}
              >
                <Text size="body.bold.md">{label}</Text>
              </Box>
            </Button>
          )
        )}
      </SimpleGrid>
    </Section>
  );
}
